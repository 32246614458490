// example theme.js

//const robotoCondensedPath =
//  "https://themes.googleusercontent.com/static/fonts/robotocondensed/v9"

const Theme = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1600px", "1920px"],
  colors: {
    text: "#000",
    textContrast: "#fff",
    textGrey: "#777777",
    background: "#fff",
    primary: "#a61700",
    offwhite: "#f8f8f8",
    footerBg: "#fff",
    subfooterBg: "#E8E8E8",
    menuBg: "#000",
    imageBg: "#000",
    cardSectionBg: "#ECECEC",
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Bebas Neue', sans-serif;",
    monospace: "Menlo, monospace",
  },
  //fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontSizes: [
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "10rem",
  ],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 600,
    light: 300,
    button: 600,
  },
  lineHeights: {
    body: 2,
    heading: 0.875,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 1664,
    avatar: 48,
    fourThree: "75%",
    sixteenNine: "56.25%",
  },
  radii: {
    default: 4,
    circle: 99999,
    button: 999,
  },
  shadows: {
    old: "0 16px 40px 0 rgba(0, 0, 0, .06)",
    card: "0 16px 40px 0 rgba(0, 0, 0, .12)",
    cardHover: "0 16px 40px 0 rgba(0, 0, 0, .25)",
  },

  // Variants
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [6, 7, null, null, null, 8],
    },
    underline: {
      content: "''",
      width: "200px",
      height: "1px",
      background: "#000",
      mt: "4",
      mb: "4",
    },
    underlined: {
      variant: "text.heading",
      "&::after": {
        variant: "text.underline",
        display: "block",
      },
    },
    underlinedMobile: {
      variant: "text.heading",
      "&::after": {
        variant: "text.underline",
        display: ["block", null, "none"],
      },
    },
    bodyHeading: {
      maxWidth: "15ch",
      fontFamily: "body",
      fontWeight: "600",
      fontSize: [4, 5, 5, 5],
      textTransform: "uppercase",
      mb: 4,
      lineHeight: "heading",
      display: "inline-block",
    },
    herotitle: {
      variant: "text.heading",
      fontSize: [
        "clamp(3.75rem, 1rem + 12vw, 10rem)",
        "clamp(3.75rem, 1rem + 14vw, 10rem)",
        "clamp(3.75rem, 1rem + 14vw, 10rem)",
        null,
        null,
        null,
      ],
      textTransform: "uppercase",
      lineHeight: "0.8",
      color: "#fff",
      width: "12ch",
      // animation:
      //   "slowSlideIn 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards, appear 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards",
      //animation: "outer-left 1s 1s ease both",
    },
    splitTitle: {
      whiteSpace: "pre",
      mr: ["12px", 3, "24px"],
      animation: "text-clip 1.5s cubic-bezier(0.5, 0, 0.1, 1) 0.5s both",
    },
    smallTitle: {
      textTransform: "uppercase",
      fontSize: 2,
      fontWeight: "bold",
      display: "block",
      mb: 1,
      color: "textGrey",
    },
    preTitle: {
      textTransform: "uppercase",
      fontSize: "1",
      fontWeight: "bold",
      color: "textGrey",
    },
    titleOffsetLeft: {
      variant: "text.heading",
      ml: [null, null, null, "-6ch"],
      maxWidth: "12ch",
      fontSize: [6, 8],
      mt: ["-75px", "-100px", null, 5],
      mb: [0, null, null, 4],
    },
    titleOffsetRight: {
      variant: "text.titleOffsetLeft",
      mr: [null, null, null, "-6ch"],
      ml: [null, null, null, null],
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
    richtext: {
      "& a": {
        color: "text",
        fontWeight: "400",
      },
    },
  },
  buttons: {
    primary: {
      color: "#fff",
      px: ["32px", "40px"],
      py: [3, 3],
      background: "#000",
      fontSize: [1, 2],
      fontWeight: "button",
      fontFamily: "body",
      borderRadius: "button",
      textTransform: "uppercase",
      lineHeight: "1.25",
      transition: "all 200ms ease-out",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
        color: "#000",
        boxShadow: "inset 0 0 0 2px",
      },
    },
    primarySmall: {
      variant: "buttons.primary",
      px: "24px",
      py: "12px",
      fontSize: 0,
    },
    secondary: {
      color: "#000",
      px: ["32px", "40px"],
      py: ["12px", 3],
      background: "#fff",
      fontSize: [1, 2],
      fontWeight: "button",
      fontFamily: "body",
      borderRadius: "button",
      textTransform: "uppercase",
      lineHeight: "1.25",
      transition: "all 200ms ease-out",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
        color: "#fff",
        boxShadow: "inset 0 0 0 2px",
      },
    },
    secondarySmall: {
      variant: "buttons.secondary",
      px: "24px",
      py: "12px",
      fontSize: 0,
    },
    secondaryMedium: {
      variant: "buttons.secondary",
      px: ["24px", "24px", "24px", "40px"],
      py: ["12px", "12px", "12px", 3],
      fontSize: [0, 0, 0, 2],
    },
    secondaryCard: {
      variant: "buttons.secondary",
      px: ["32px", "24px", "24px", "24px", "40px"],
      py: ["12px", "12px", "12px", "12px", 3],
      fontSize: [1, 0, 0, 1, 1, 2],
    },
    secondaryHero: {
      variant: "buttons.secondary",
      animation: "button-clip 1.5s cubic-bezier(0.5, 0, 0.1, 1) 0.5s both",
    },
    outline: {
      variant: "buttons.primary",
      backgroundImage: "none",
      color: "text",
      bg: "transparent",
      boxShadow: "inset 0 0 0 2px",
      borderRadius: "button",
      "&:hover": {
        cursor: "pointer",
        bg: "#000",
        color: "#fff",
        boxShadow: "none",
      },
    },
    outlineWhite: {
      variant: "buttons.outline",
      color: "offwhite",
      boxShadow: "inset 0 0 0 2px #f8f8f8",
      "&:hover": {
        cursor: "pointer",
        bg: "#fff",
        color: "#000",
        boxShadow: "none",
      },
    },
    playButton: {
      variant: "buttons.outlineWhite",
      py: ["0", "0"],
      px: ["0", "0"],
      width: "64px",
      height: "64px",
      fontSize: "0px",
      "& > svg": {
        margin: "auto",
      },
      "&:hover": {
        boxShadow: "inset 0 0 0 2px transparent",
        background: "#fff",
        "& > svg": {
          fill: "#000",
          stroke: "#000",
          transition: "all 300ms ease-out",
        },
      },
    },
    arrowLink: {
      py: 2,
      px: 0,
      //mb: 4,
      fontFamily: "body",
      background: "transparent",
      textTransform: "uppercase",
      fontWeight: "button",
      lineHeight: "body",
      color: "text",
      textDecoration: "none",
      display: "inline-block",
      textAlign: "left",
      //whiteSpace: "nowrap",
      overflow: "hidden",

      "&::after": {
        content: "''",
        mt: 2,
        display: "block",
        width: "100%",
        height: "1px",
        backgroundColor: "#000",
        //transition: "all 500ms ease-out",
        animationDuration: "600ms",
      },
      "&:hover": {
        cursor: "pointer",
        "&::after": {
          //opacity: "0",
          animationName: "slideunderline",
          aninationTimingFuntion: "ease-in-out",
        },
      },
    },
  },
  forms: {
    checkbox: {
      fill: "text",
    },
  },
  layout: {
    container: {
      marginTop: 0,
      marginBottom: 5,
      marginLeft: [4, 4, 5, 5],
      marginRight: [4, 4, 5, 5],
      maxWidth: "100%",
    },
    fixed: {
      marginTop: 0,
      marginBottom: 5,
      marginLeft: [4, 4, 5, 5, "auto"],
      marginRight: [4, 4, 5, 5, "auto"],
      paddingLeft: [0, 0, 0, 0],
      paddingRight: [0, 0, 0, 0],
      maxWidth: "container",
    },
    mobilefw: {
      variant: "variants.fixed",
      marginLeft: [0, 4, 5, 5, "auto"],
      marginRight: [0, 4, 5, 5, "auto"],
    },
  },
  grids: {
    bodyGrid: {
      pl: [4, null, 5, null, 6],
      pr: [4, null, 5, null, 6],
    },
    imageText: {
      //gridTemplateColumns: ["1fr", null, null, "1fr 0.665fr", "1fr 0.7fr"],
      gridTemplateColumns: [
        "1fr",
        null,
        null,
        "128px 1fr 0.7fr 64px",
        "192px 1fr 0.7fr 128px",
        null,
        "calc(50vw - 832px) 1005px 659px calc(50vw - 832px)",
        //"calc(50vw - 1088px) 128px minmax(941px, 1100px) 64px minmax(659px, 765px) 128px calc(50vw - 1088px)",
      ],
      //gridTemplateRows: ["auto", null, null, "100vh"],
      //maxHeight: [null, null, null, "80vw"],
      //gridTemplateAreas: ["'image' 'text'", null, null, "'image text'"],
      gridTemplateAreas: [
        "'image' 'text'",
        null,
        null,
        "'image image text .'",
        null,
        null,
        "' image image text . '",
      ],
    },
    imageTextReverse: {
      variant: "variants.imageText",
      gridTemplateAreas: ["'image' 'text'", null, null, "'text image'"],
      gridTemplateColumns: ["1fr", null, null, "5fr 7fr"],
    },
    imageTextOverlayGrid: {
      rowGap: [0],
      gridTemplateAreas: "'preTitle' 'title' 'text'",
      maxWidth: "1024px",
    },
    titleText: {
      gap: [4, null, null, 5],
      rowGap: [0, 0, 0, 0],
      gridTemplateColumns: ["100%", "100%", "2fr 3fr"],
      gridTemplateRows: ["auto auto", "auto auto", "auto"],
      gridTemplateAreas: ["'title' 'text'", null, "'title text'"],
      "& h2:fist-of-type": {
        variant: "underlinedMobile",
      },
    },
    titleIntroText: {
      gap: [4, null, null, 5],
      rowGap: [0, 0, 0, 0],
      gridTemplateColumns: ["100%", "100%", "2fr 3fr"],
      gridTemplateRows: ["auto auto auto", null, "auto auto"],
      gridTemplateAreas: [
        "'title' 'intro' 'text'",
        null,
        "'title .' 'intro text'",
      ],
      "& h2:fist-of-type": {
        variant: "underlined",
      },
    },
    titleTextGallery: {
      gap: ["4", "4", "4", "5"],
      gridTemplateColumns: [
        "0px 1fr 1fr 0px",
        "0px 1fr 0.7fr 0px",
        "32px 1fr 0.7fr 32px",
        "0px 1fr 0.7fr 0px",
        "64px 1fr 0.7fr 64px",
        "1fr 0.7fr",
      ],
      gridTemplateRows: [
        "auto auto 64px 96px auto",
        "auto auto auto auto auto",
        "auto auto 300px auto",
        "auto auto 500px auto",
      ],
      gridTemplateAreas: [
        "'. title title .' '. intro image2 image2' 'image1 image1 image2 image2' 'image1 image1 . .' '. text text .'",
        null,
        "'. title . .' '. intro image2 image2' 'image1 image1 image2 image2' 'image1 image1 text .'",
        null,
        null,
        "'title .' 'intro image2' 'image1 image2' 'image1 text'",
      ],
      "& picture > img": {
        height: "100%",
      },
    },
    titleTextGalleryNew: {
      gap: ["2", "3", "4", "5"],
      gridRowGap: ["4", "4"],
      gridTemplateColumns: [
        "24px 1fr 0.7fr 24px",
        "16px 1fr 0.7fr 16px",
        "32px 1fr 0.7fr 32px",
        "0px 1fr 0.7fr 0px",
        "64px 1fr 0.7fr 64px",
        "1fr 0.7fr",
      ],
      gridTemplateRows: [
        "auto auto 296px auto",
        "auto auto auto 350px auto",
        "auto auto 300px auto",
        "auto auto 500px auto",
      ],
      gridTemplateAreas: [
        "'. title title .' '. intro intro .'  'image1 image1 image2 image2' '. text text .'",
        null,
        "'. title . .' '. intro image2 image2' 'image1 image1 image2 image2' 'image1 image1 text .'",
        null,
        null,
        "'title .' 'intro image2' 'image1 image2' 'image1 text'",
      ],
      "& picture > img": {
        height: "100%",
      },
    },
    titleTextNews: {
      //variant: "grids.titleText",
      columnGap: [0, null, null, 5],
      rowGap: 0,
      gridTemplateColumns: ["100%", null, null, "2fr 3fr"],
      gridTemplateRows: ["auto auto auto", null, "auto auto"],
      gridTemplateAreas: [
        "'preTitle' 'title' 'text'",
        null,
        null,
        "'title text' 'preTitle text'",
      ],
      "& h3::after": { display: [null, null, null, "none"] },
    },
  },
  cards: {
    primary: {
      //p: 2,
      bg: "background",
      boxShadow: "card",
      transition: "0.5s ease-out",
      "& picture": {
        "&::before": {
          transition: "0.5s ease-out",
        },
      },
      "@media (hover: hover)": {
        "&:hover": {
          boxShadow: "cardHover",
          transform: "scale(1.015)",
          "& picture": {
            "&::before": {
              opacity: "0.5",
            },
          },
        },
      },
    },
  },

  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle",
    },
    cardContainer: {
      overflow: "visible",
      flexGrow: "1",
      "& > a": {
        color: "text",
        textDecoration: "none",
        display: "flex",
        flexGrow: "1",
      },
    },
    link: {
      color: "primary",
    },
    nav: {
      fontSize: 1,
      fontWeight: "bold",
      display: "inline-block",
      p: 2,
      color: "inherit",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "primary",
      },
    },
    navItem: {
      listStyleType: "none",
      display: "flex",
      lineHeight: "1",
      textTransform: "uppercase",
      fontSize: [4, 4, 5],
      "& a": {
        my: 2,
        color: "textContrast",
        textDecoration: "none",
        width: ["100%", null, "auto"],
        "&::after": {
          content: "''",
          width: "60px",
          maxWidth: "100%",
          height: "1px",
          display: "block",
          position: "absolute",
          mt: 2,
          backgroundColor: "#fff",
          opacity: 0,
          transition: "opacity 0.2s linear",
        },
        "&.active": {
          cursor: "default",
          "&::after": {
            opacity: 1,
          },
        },
        "&:hover": {
          "&::after": {
            opacity: 1,
          },
        },
      },
    },
    // secondaryNavItem: {
    //   variant: "variants.navItem",
    //   borderBottom: "1px solid hsla(0, 0%, 80%, 0.8)",
    //   "& > a": {
    //     fontSize: 2,
    //     my: [3, null, 3],
    //     "&::after": {
    //       display: "none",
    //     },
    //   },
    // },

    subFooterNavItem: {
      //variant: "variants.navItem",
      listStyleType: "none",
      display: "flex",
      "& > a": {
        fontSize: 0,
        color: "text",
        textDecoration: "none",
      },
    },
    accordionNavItem: {
      variant: "variants.navItem",
      //my: 2,
      "& > a": {
        fontSize: 1,
        textTransform: "none",
      },
    },
    accordionNavItemFooter: {
      variant: "variants.navItem",
      my: 2,
      "& > a": {
        fontSize: 2,
        textTransform: "none",
        color: "text",
      },
    },
    newsLink: {
      fontSize: 1,
      lineHeight: 1.25,
      mb: 2,
      "& a": {
        color: "text",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    newsMenuSection: {
      flexDirection: "column",
      width: "100%",
      fontWeight: "bold",
      textTransform: "uppercase",
      mt: 4,
      mb: 3,
      //borderBottom: "1px solid #000",
      "&::after": {
        content: "''",
        display: "inline-block",
        width: "32px",
        height: "1px",
        backgroundColor: "text",
      },
    },
    imgheader: {
      backgroundImage:
        "linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.1)  , rgba(0,0,0,0))",
    },
    section: {
      variant: "grids.bodyGrid",
      //pl: [4, null, 5, null, 6],
      //pr: [4, null, 5, null, 6],
      mb: [5, null, null, null, 6],
    },
    sectionNoMargin: {
      variant: "grids.bodyGrid",
      //pl: [4, null, 5, null, 6],
      //pr: [4, null, 5, null, 6],
    },
    cardSection: {
      variant: "variants.section",
      backgroundColor: "cardSectionBg",
      overflow: "visible",
      pt: [5],
      pb: [5],
      "& > div": {
        overflow: "visible",
      },
    },
    fluid: {
      pl: [0, 0, 0],
      pr: [0, 0, 0],
      "& > div": {
        mx: 0,
        maxWidth: "100%",
      },
    },
    singleImageText: {
      width: "100%",
      ml: [4, null, 5],
      mr: [4, null, 5, 0],
      mt: [4, 5, 5, 5],
      mb: [4, 5, 5, 5],
      "& a > button": { mb: [null, null, null, 4] },
    },
    blokColumn: {
      "& > div": {
        mr: 0,
        ml: 0,
        mb: 3,
      },
      "& button": {
        mb: 0,
      },
    },
    languageSelector: {
      display: "inline-flex",
      paddingInlineStart: "0",
      "& > li": {
        textDecoration: "none",
        display: "flex",
        "&::after": {
          content: "''",
          height: "12px",
          width: "1px",
          display: "block",
          backgroundColor: "textContrast",
          alignSelf: "center",
          mx: [2, null, 3],
        },
        "&:last-of-type": {
          "::after": {
            display: "none",
          },
        },
        "& > a": {
          lineHeight: "1",
          color: "textContrast",
          textTransform: "uppercase",
          textDecoration: "none",
        },
      },
    },
    languageSelectorFooter: {
      variant: "variants.languageSelector",
      listStyleType: "none",
      "& > li": {
        display: "flex",
        "&::after": {
          content: "''",
          height: "8px",
          width: "1px",
          display: "block",
          backgroundColor: "text",
          alignSelf: "center",
          mx: 2,
        },
        "&:last-of-type": {
          "::after": {
            display: "none",
          },
        },
        "& > a": {
          color: "body",
          //borderRight: "1px solid #000",
          my: "0",
          lineHeight: "1",
          textDecoration: "none",
          fontSize: [1, null, null, 0],
        },
      },
    },
    coverImageContainer: {
      overflow: "hidden",
      width: "100%",
      height: "100%",
      display: "inline",
    },
    coverImage: {
      overflow: "hidden",
      width: "100%",
      minHeight: "100%",
      display: "flex",
      "& img": {
        objectFit: "cover",
        objectPosition: "center",
        minHeight: "100%",
        width: "100%",
        //backgroundColor: "#000",
      },
    },
    intro: { fontSize: ["3", "3", "3", "3", "4"] },

    mobileFw: {
      marginTop: 0,
      marginBottom: 5,
      marginLeft: [0, 4],
      marginRight: [0, 4],
      maxWidth: "100%",
    },
    nomargin: {
      marginTop: 0,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0,
      maxWidth: "100%",
    },
    hero: {
      width: "100vw",
      height: "100vh",
      minHeight: "25rem",
      overflow: "hidden",
      mb: 5,
      mt: ["-80px", "-120px"],
      position: "relative",
      background: "#000",
    },
    heroImage: {
      variant: "variants.hero",
      "&::before": {
        content: "''",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "5",
        background:
          "linear-gradient(to bottom, rgba(0,0,0,0.4) 0, rgba(0,0,0,0) 100%)",
      },
      "& picture": {
        animation:
          "image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 1.2s backwards, scalein 2.5s cubic-bezier(.34,.43,.76,1) 1.2s forwards",
        //opacity: "0",
        // animation:
        //   "appear 1.5s ease-out 0.4s forwards, scalein 5.5s cubic-bezier(.34,.43,.76,1) 0.2s forwards",
      },
    },
    heroImage100: {
      variant: "variants.heroImage",
      height: ["100vh", "100vh", "100vh", "100vh"],
    },
    herohome: {
      variant: "variants.hero",
      mb: 0,
      "&::before": {
        content: "''",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "5",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 88.1%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.49) 0%, rgba(0, 0, 0, 0) 100%)",
      },
      "& picture": {
        animation:
          "image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 1.2s backwards, scalein 2.5s cubic-bezier(.34,.43,.76,1) 1.2s forwards",
        "& > img": {
          //minHeight: "105% !important",
          //opacity: "0",
          // animation: "scalein 5.5s cubic-bezier(.34,.43,.76,1) 1s forwards",
        },
      },
    },
    gradientBefore: {
      content: "''",
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: "5",
    },
    gradientTop: {
      "&::before": {
        variant: "variants.gradientBefore",
        background:
          "linear-gradient(to bottom, rgba(0,0,0,0.4) 0, rgba(0,0,0,0) 100%)",
      },
    },
    gradientBottom: {
      "&::before": {
        variant: "variants.gradientBefore",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 88.1%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.49) 0%, rgba(0, 0, 0, 0) 100%)",
      },
    },
    gradientBottomLight: {
      "&::before": {
        variant: "variants.gradientBefore",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 61.17%, #000000 100%)",
      },
    },
    gradientFull: {
      variant: "variants.coverImage",
      "&::before": {
        variant: "variants.gradientBefore",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.4) 100%)",
      },
    },
    gradientSlider: {
      variant: "variants.coverImage",
      "&::before": {
        variant: "variants.gradientBefore",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.4) 100%)",
      },
    },
    gradientDark: {
      variant: "variants.coverImage",
      position: "relative",
      "&::before": {
        variant: "variants.gradientBefore",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.4) 100%)",
      },
    },
    badge: {
      display: "inline-block",
      color: "black",
      bg: "#ececf4",
      fontSize: "0",
      mt: 1,
      mr: 1,
      px: 2,
      py: "2px",
      borderRadius: 0,
    },
    mobilebadge: {
      display: ["inline-block", "block"],
      color: ["black", "text"],
      bg: ["#ececf4", "transparent"],
      fontSize: [0, 2],
      mt: [1, 0],
      mr: [1, 0],
      px: [2, 0],
      py: ["2px", 0],
      borderRadius: 0,
    },
    badgescroll: {
      flexWrap: "nowrap",
      overflowX: ["auto", "auto", "hidden", "hidden"],
      overflowY: "hidden",
      minWidth: ["100%"],
      "::-webkit-scrollbar": {
        display: "none",
      },
    },
    mobilebadgescroll: {
      flexWrap: ["nowrap", "wrap"],
      overflowX: ["auto", "hidden"],
      overflowY: "hidden",
      minWidth: ["100%"],
      "::-webkit-scrollbar": {
        display: "none",
      },
    },
    mobilescroll: {
      flexWrap: ["nowrap", "nowrap", "wrap"],
      overflowX: ["auto", "auto", "visible"],
      //overflowY: ["hidden", "hidden", "visible"],
      //justifyContent: ["", "", "", "center"],
      //minWidth: ["100%", "100%", "100%", "auto"],
      maxWidth: [null, null, "container"],
      mb: 5,
      marginLeft: [0, 0, 5, 5, "auto"],
      marginRight: [0, 0, 5, 5, "auto"],
      pl: [3, 3, 0],
      "::-webkit-scrollbar": {
        display: "none",
      },
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    },
    mobilescrollfw: {
      variant: "variants.mobilescroll",
      ml: [0, 0, 0, 0, 0],
      mr: [0, 0, 0, 0, 0],
      maxWidth: ["auto", "auto", "auto", "auto"],
    },
    navCard: {
      //position: "relative",
      //my: "4",
      "& > a": {
        //position: "absolute",
        overflow: "hidden",
        mt: 0,
        height: "100%",
        width: "100%",
        top: "0",
        "& > div": { height: "100%" },
      },
      "& span": {
        fontSize: ["2.5rem", "2.5rem", "2.25rem", "2.5rem", "6"],
        left: [3, 3, 3, 4, 4],
        top: [3, 3, 3, 4, 4],
        width: "12ch",
      },
      "& picture": {
        pt: ["56.25%", null, "56.25%"],
        position: "relative",
        "& > img": {
          position: "absolute",
          top: "0",
          height: "100%",
        },
      },
    },
    introCard: {
      "& > a": {
        "& > div": {
          height: ["480px", null, null, "800px", null],
          maxHeight: "80vh",
        },
      },
    },
    cardcontainer: {
      //REPLACE, change to grid
      flexWrap: "wrap",
      flexDirection: "row",
      overflow: "visible",
      //justifyContent: ["", "", "", "center"],
      //minWidth: ["100%", "100%", "100%", "auto"],
      maxWidth: "container",
      px: [3, 3, 4, 5],
      mb: 5,
      marginLeft: [4, 3, 4, 4, "auto"],
      marginRight: [4, 3, 4, 4, "auto"],
      "& > div:first-of-type > div": {
        ml: "0",
      },
      "& > div:last-of-type > div": {
        mr: "0",
      },
    },
    collumncard: {
      bg: ["background", "background", "background", "transparent"],
      boxShadow: ["card", "card", "card", "none"],
      py: 4,
      px: [4, 4, 4, 5],
      mt: [-4, -5, -5, 0],
      mx: [4, 4, 5, 0],
    },
    // arrowLink: {
    //   py: 2,
    //   //mb: 4,
    //   textTransform: "uppercase",
    //   //borderBottom: "1px solid",
    //   //borderColor: "text",
    //   fontWeight: "button",
    //   lineHeight: "body",
    //   color: "text",
    //   textDecoration: "none",
    //   display: "inline-block",
    //   whiteSpace: "nowrap",
    //   overflow: "hidden",

    //   "&::after": {
    //     content: "''",
    //     mt: 2,
    //     display: "block",
    //     width: "100%",
    //     height: "1px",
    //     backgroundColor: "#000",
    //     //transition: "all 500ms ease-out",
    //     animationDuration: "600ms",
    //   },
    //   "&:hover": {
    //     "&::after": {
    //       //opacity: "0",
    //       animationName: "slideunderline",
    //       aninationTimingFuntion: "ease-in-out",
    //     },
    //   },
    // },
    arrowRight: {
      position: "relative",
      display: "inline-block",
      verticalAlign: "middle",
      width: "6px",
      height: "6px",
      borderWidth: "1px 1px 0 0",
      borderStyle: "solid",
      margin: "10px 1px 10px 40px",
      borderColor: "inherit",
      transform: "rotate(45deg)",
      "::before": {
        content: "''",
        right: "0",
        top: "-1px",
        position: "absolute",
        height: "1px",
        boxShadow: "inset 0 0 0 32px",
        transform: "rotate(-45deg)",
        width: "40px",
        transformOrigin: "right top",
      },
      "::after": {
        content: "''",
      },
    },
    sliderButton: {
      backgroundColor: "rgba(255, 255, 255, 1)",
      height: ["64px", "96px"],
      width: ["32px", "48px"],
      fill: "#fff",
      opacity: "1",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      outline: "none",
      cursor: "pointer",
      top: "50%",
      marginTop: [-3, null, -5],
      zIndex: "100",
      border: "none",
      "&:before": {
        display: "none",
      },
      "&:hover": {
        opacity: "1",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      },
      "& svg": {
        height: ["16px", "32px"],
        stroke: "#000",
      },
      // "&.swiper-button-disabled": {
      //   visibility: "hidden",
      //   opacity: 0,
      // },
    },
    cardSliderButton: {
      variant: "variants.sliderButton",
      borderRadius: "999px !important",
      boxShadow: "card",
      height: ["64px"],
      width: ["64px"],
      opacity: "1",
      backgroundColor: "#fff",
      marginTop: [-3, null, -4],
      justifyContent: "center !important",
      transition: "0.5s ease-out",
      "& > svg": {
        ml: "0 !important",
        mr: "0 !important",
        height: "24px",
        fill: "#000",
        stroke: "black",
      },
      "&:hover": {
        transform: "scale(1.05)",
      },
      "&.prevarrow": {
        ml: ["-24px", null, "-48px", null, null, "-32px"],
      },
      "&.nextarrow": {
        right: ["-24px", null, "-48px", null, null, "-32px"],
      },
      "&.swiper-button-disabled": {
        visibility: "hidden",
        opacity: 0,
      },
    },
    smallGalleryBlack: {
      variant: "variants.section",
      //px: ["0", ""],
      backgroundColor: "#000",
      pb: [4, null, 5],
      "& picture": {
        position: "relative",
        // "&:hover": {
        //   "&::before": {
        //     opacity: "0",
        //     transition: "0.3s",
        //   },
        // },
        // "&::before": {
        //   transition: "0.3s",
        //   content: "''",
        //   height: "100%",
        //   width: "100%",
        //   position: "absolute",
        //   background:
        //     "linear-gradient(0deg, rgba(26, 23, 23, 0.4), rgba(26, 23, 23, 0.4))",
        // },
      },
    },
    labelValue: {
      display: "inline-flex !important",
      flexDirection: "column",
      lineHeight: "1.2",
      fontFamily: "body",
      "& > span:first-of-type": {
        fontSize: [2, null, 3],
        fontWeight: "600",
        textTransform: "uppercase",
        mb: "2",
      },
      "& > div:last-of-type > span": {
        fontSize: [1, null, 2],
        fontWeight: "400",
      },
    },
    labelValueSmall: {
      variant: "variants.labelValue",
      "& > span:first-of-type": {
        fontSize: "2",
        fontWeight: "600",
        textTransform: "uppercase",
        mb: "2",
      },
      "& > div:last-of-type > span": {
        fontSize: "1",
        fontWeight: "400",
      },
    },
    accordion: {
      flexDirection: "column",
      borderTop: "1px solid hsla(0, 0%, 80%, 0.8)",
      // "&:first-of-type": {
      //   borderTop: "none",
      // },
      "&:last-of-type": {
        borderBottom: "1px solid hsla(0, 0%, 80%, 0.8)",
      },
    },
    accordionButton: {
      backgroundColor: "transparent",
      cursor: "pointer",
      py: "4",
      pr: "0",
      pl: "0",
      alignItems: "center",
      justifyContent: "space-between",
      border: "none",
      outline: "none",
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "2",
      transition: "0.6s ease",
      textAlign: "left",
      "& > span": {
        width: "calc(100% - 56px)",
      },
    },
    ulReset: {
      listStyleType: "none",
      paddingInlineStart: 0,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      //background: "#FDFDFD",
      overflowX: "hidden",
      p: {
        //fontSize: [2, 2, 2, 2, 3],
        fontSize: 2,
        marginBlockEnd: "1.5rem",
        marginBlockStart: "0",
      },
      a: {
        color: "text",
      },
      h1: {
        variant: "text.heading",
        fontSize: [8],
        mt: 2,
        mb: 3,
      },
      h2: {
        variant: "text.heading",
        //fontSize: [4, 4, 5, 5, 6],
        fontSize: [6, 6, 6, 6, 7, 8],
        mt: 2,
        mb: 3,
      },
      h3: {
        variant: "text.heading",
        //fontSize: [3, 3, 4],
        mt: 2,
        mb: 2,
      },
      h5: {
        variant: "text.body",
        fontSize: [3],
        textTransform: "uppercase",
        mt: 0,
        mb: 0,
      },
      h6: {
        variant: "text.body",
        fontSize: [2],
        textTransform: "uppercase",
        mt: 0,
        mb: 1,
      },
      blockquote: {
        marginBlockEnd: 0,
        marginBlockStart: 0,
        marginInlineStart: 0,
        marginInlineEnd: 0,
        fontSize: [4, 4, 5],
        fontStyle: "italic",
        fontWeight: "light",
        "& > p": {
          fontSize: [3, 3, 4],
        },
      },
    },
  },
}

export default Theme
